import React from 'react';
import {Card, Form, Input, Button, Statistic, Col, InputNumber} from 'antd';
import {users} from "../../../../../../../API/TT_ELD/Users/user";
import { useParams} from "react-router-dom";
import {useMutation,UseMutationResult} from "react-query";
import { useCompanyData} from "../../../../../../../Hooks/TT_ELD/Companies";

const { Countdown } = Statistic;
type MyObjectType = {
    [key: string | number]: any; // Индексная подпись с параметром типа 'string'
};
type params = {
    readonly id: any
}
interface FreeTrialResponse {
    data: any;
    error: string;
}

const FreeTrial = () => {
    const [form] = Form.useForm();
    const {id} = useParams<params>();
    const {data ,isFetching, isLoading ,refetch}: MyObjectType = useCompanyData(id);
    const timestamp1 = data?.stripe?.subscription?.trial_start
    const timestamp2 = data?.stripe?.subscription?.trial_end
    const status = data?.stripe?.subscription?.status
    let days
    let date1
    let date2:any
    if (typeof timestamp1 === 'number' && typeof timestamp2 === 'number') {
        date1 = new Date(timestamp1 * 1000);
        date2 = new Date(timestamp2 * 1000);
        const timeDiff = Math.abs(date2.getTime() - date1.getTime());
        const daysDiff = Math.floor(timeDiff / (1000 * 3600 * 24));
        days = daysDiff > 0 ? `${daysDiff} ${daysDiff > 1 ? '' : ''}` : null;
    }


    const onPost: UseMutationResult<FreeTrialResponse, unknown, { values: string | number; id: string | number }> = useMutation(
        'freeTrial',
        ({ values, id }) => users.freeTrial(values, id),
        {
            onSuccess: (res:any) => {
                refetch()
                if (res.message) {
                    form.resetFields();
                }
            },
        }
    );

    const onFinish = async (values: any) => {
            await onPost.mutate({values, id});
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    type FieldType = {
        limit?: number;
        quantity?: string;
    };

    return (
        <div className='mainPage'>
                <Card loading={isLoading || isFetching} title="Free trial"  style={{ width: '100%' }} >
                    <Form
                        form={form}
                        layout='vertical'
                        style={{ maxWidth: 600 }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        name="basic"
                        initialValues={{ ...data?.stripe?.subscription ,  limit : days ? Number(days) : 7}}
                    >
                        {status === 'trialing' && <Col  style={{ marginBottom: 32 }} span={12}>
                            <Countdown style={{fontSize: '1px'}} title="Between dates ends" value={date2}
                                       format=" DD : HH : mm : ss "/>
                        </Col>}

                        {!data?.stripe?.subscription?.id && <Form.Item<FieldType>
                            label="Limit"
                            name="limit"
                            rules={[{required: true, message: 'Please input your limit!'}]}
                        >
                            <InputNumber  max={100} min={1} style={{width: '100%'}}/>
                        </Form.Item>}
                        <Form.Item<FieldType>
                            label="Quantity"
                            name="quantity"
                            rules={[{ required: true, message: 'Please input your quantity!' }]}
                        >
                            <InputNumber max={100} min={1}  style={{width: '100%'}}/>
                        </Form.Item>
                        <Form.Item >
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
        </div>
    );
};

export default FreeTrial;